.bg-custom {
    background-color: #28283d !important;
}

.paddingon-right{
    padding-right: 0px !important;
}

/* The container for the hamburger icon */
.navbar-toggler-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;  /* Ensures space between lines */
    align-items: center;
    width: 30px;  /* Width of the container */
    height: 24px; /* Total height to accommodate space between lines */
}

/* Each line */
.line {
    width: 100%;
    height: 3px;  /* Thickness of the line */
    background-color: black;  /* Line color */
    border-radius: 0;  /* Sharp edges */
}

/* Ensure consistent spacing between lines */
.navbar-toggler-icon .line:nth-child(1) {
    margin-bottom: 4px;  /* Space between the first and second line */
}

.navbar-toggler-icon .line:nth-child(2) {
    margin-bottom: 4px;  /* Space between the second and third line */
}


/* Add margin to space out the lines properly */
.navbar-toggler-icon .line:nth-child(1) {
    margin-bottom: 4px;  /* Adds space between the top and middle line */
}

.navbar-toggler-icon .line:nth-child(2) {
    margin-top: 4px;  /* Adds space between the middle and bottom line */
}



.sp-card .border-dashed {
    min-width: 110px !important;
}

.logo-container {
    height: 120px;
    width: 120px;
    background-color: #6f4e37;
    border-radius: 10%;
}

.border-color {
    border-color: #D9D9D9;
}

.custom-placeholder::placeholder {
    color: #999999 !important;
    opacity: 1;
}

.badge-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #EC345C;
}

.border-solid {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    padding: 8px;
}

.point-up {
    transform: rotate(180deg);
}

.box {
    width: 170px;
    height: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.box > i {
    font-size: 18px;
    color: #d9d9d9;
}

.box > input {
    height: 20px;
    border: none;
    width: 100%;
    outline: none;
    font-size: 18px;
    padding-left: 10px;
    /* display: none; */
}

.button-transparent {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
}

.button-transparent > i {
    font-size: 18px;
    color: #d9d9d9;
}

.hover-black:hover {
    background-color: transparent !important;
    border-color: black !important;
    color: white !important;
}

.hover-black-custom:hover {
    background-color: transparent !important;
    border-color: black !important;
    color: black !important;
}

.highcharts-credits {
    display: none;
}

.w-50px {
    width: 50px;
}

.w-13px {
    width: 13px;
}

.w-15px {
    width: 15px;
}

.w-35px {
    width: 35px;
}

.w-5px {
    width: 5px;
}

.w-75px {
    width: 75px;
}

.w-100px {
    width: 100px;
}

.w-120px {
    width: 120px;
}

.w-150px {
    width: 150px;
}

.w-200px {
    width: 200px;
}

.w-250px {
    height: 250px;
}

.w-442px {
    width: 442px;
}

.w-35 {
    width: 35%;
}

.h-15px {
    height: 15px;
}

.h-35px {
    height: 35px;
}

.h-40px {
    height: 40px;
}

.h-15px {
    height: 15px;
}

.h-50px {
    height: 50px;
}

.h-100px {
    height: 100px;
}

.h-120px {
    height: 100px;
}

.h-157px {
    height: 157px;
}

.h-150px {
    height: 150px;
}

.h-200px {
    height: 200px;
}

.h-290px {
    height: 290px;
}

.h-300px {
    height: 300px;
}

.h-20px {
    height: 20px;
}

.h-17px {
    height: 17px;
}

.br-40px {
    border-radius: 40px !important;
}

.mt-65px {
    margin-top: 65px;
}

.mt-54px {
    margin-top: 54px;
}

.mt-180px {
    margin-top: 180px;
}


.mt-125px {
    margin-top: 125px;
}

.min-height-400px {
    min-height: 400px;
}

.min-height-200px {
    min-height: 200px;
}

.min-height-270px {
    min-height: 270px;
}

.min-height-360px {
    min-height: 360px;
}

.min-height-50px {
    min-height: 50px;
}

.min-height-60px {
    min-height: 60px;
}

.min-height-160px {
    min-height: 160px;
}

.max-height-50px {
    min-height: 50px;
}

.max-width-100 {
    max-width: 100%;
}

.h-330px {
    min-height: 330px;
}

.h-180px {
    min-height: 180px;
}

.h-50px {
    height: 50px;
}

.h-75vh {
    height: 75vh;
}

.card-bordered.h-max-content {
    height: max-content;
}

.input-checkbox {
    background-color: var(--bs-secondary);
}

.button-transparent.text-bg-transparent {
    background-color: transparent;
}

.p-50px {
    padding-left: 50px;
}

.fs-48px {
    font-size: 48px;
}

.fs-18px {
    font-size: 18px;
}

.left-15px {
    left: 15px;
}

.right-15px {
    right: 15px;
}

.avatar-image {
    width: 30px !important;
    height: 40px;
}

.scrollable-container {
    max-height: calc(100vh - 200px); /* Adjust this value as needed */
    overflow-y: auto;
}

.me-0.custom-modal .modal-content {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    right: 10px;
    margin-right: 0;
}

.about-textarea {
    height: calc(100% + 57px);
}

.form-select.currency-picker,
.form-control.currency-picker {
    color: #616162;
    font-weight: 300;
}

.settings-item {
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
}

.selected-setting {
    display: inline-block;
    border: 1px solid transparent;
    background-color: #262d51;
    color: white;
}

.company-edit-button {
    top: -10px;
    right: -10px;
    z-index: 10;
    width: 30px;
    height: 30px;
    background-color: #fff !important;
    border: 1px solid #eff2f5 !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line-span {
    width: 3rem;
    height: 0.07rem;
    background-color: black;
    display: inline-block;
}

.logo-edit-button {
    bottom: -2px;
    right: -10px;
    z-index: 10;
    width: 30px;
    height: 30px;
    background-color: #fff !important;
    border: 1px solid #eff2f5 !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(270deg);
}

.dots {
    list-style: none;
    display: flex;
}

.dots span {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin: 0 1px;
    background-color: var(--bs-danger);
    -webkit-animation: wave 1s infinite;
    -moz-animation: wave 1s infinite;
    -o-animation: wave 1s infinite;
    animation: wave 1s infinite;
}

@-webkit-keyframes wave {
    0%,
    60%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    30% {
        opacity: 0.2;
        transform: translateY(-10px);
    }
}

@-moz-keyframes wave {
    0%,
    60%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    30% {
        opacity: 0.2;
        transform: translateY(-10px);
    }
}

@-o-keyframes wave {
    0%,
    60%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    30% {
        opacity: 0.2;
        transform: translateY(-10px);
    }
}

@keyframes wave {
    0%,
    60%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    30% {
        opacity: 0.2;
        transform: translateY(-10px);
    }
}

.dots span:nth-child(2) {
    animation-delay: 200ms;
}

.dots span:nth-child(3) {
    animation-delay: 400ms;
}

/*Document popup styles*/
.pop-up-dialog {
    top: 50px;
    right: 0px;
    z-index: 10;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 150px;
    background-color: #fff !important;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.edit-folder-dialog {
    top: 30px;
    left: 250px;
    z-index: 10;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 150px;
    background-color: #fff !important;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.options-dialog {
    top: 20px;
    right: 15px;
    z-index: 10;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 150px;
    background-color: #fff !important;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.options-dialog > button:hover {
    background-color: rgba(245, 248, 250, 0.8);
}

.edit-file-dialog {
    top: 110px;
    right: -100px;
    z-index: 10;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 150px;
    background-color: #fff !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.new-folder {
    border: 1px solid #eff2f5;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 150px;
    gap: 10px;
}

.upload-file {
    border: 1px solid #eff2f5;
    border-top: none;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 150px;
    color: #616162;
    gap: 10px;
}

delete-pop-up {
    top: 50px;
    right: 50px;
    z-index: 10;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 150px;
    background-color: #fff !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-spacing {
    margin-right: 8px; /* Adjust the space as needed */
}

/*Company Creation Form*/
.custom-checkbox:checked {
    background-color: #f1416c !important;
    border-color: #f1416c !important;
}

.f-s {
    font-family: 'Inter', sans-serif;
}

.separator {
    background-color: #9a9a9c !important;
    top: 20px;
    left: 20px;
    width: 60px;
    height: 2px;
    border: 1px;
}

@media (min-width: 576px) {
    .separator {
        left: 60px;
        transform: translateX(-50%);
        width: 100px;
    }
}

@media (min-width: 768px) {
    .separator {
        left: 110px;
        transform: translateX(-50%);
        width: 130px;
    }
}

@media (min-width: 992px) {
    .separator {
        left: 100px;
        transform: translateX(-50%);
        width: 120px;
    }
}

@media (min-width: 1200px) {
    .separator {
        left: 110px;
        transform: translateX(-50%);
        width: 160px;
    }
}

@media (min-width: 1400px) {
    .separator {
        left: 130px;
        transform: translateX(-50%);
        width: 170px;
    }
}

.input-group-text {
    cursor: pointer;
    border: none;
    background-color: transparent !important;
}

/*Login*/

.login-bg {
    overflow: hidden;
    padding-right: 0 !important;
}

.login-row {
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.login-content {
    padding-left: 6rem !important;
    background-color: white;
    margin: 0 !important;
    padding-right: 0;
}

.login-logo {
    width: 160px;
    height: 160px;
    top: 26px;
    padding-top: 56.85px;
    padding-bottom: 54.03px;
    left: 859px;
    /*padding: 56.85px 10.67px 54.03px 40px;*/
}

.login-bg img {
    object-fit: cover;
    object-position: left;
    width: 100%;
    z-index: 1;
    height: 100vh;
}

.login-text {
    height: 50vh;
    align-content: space-around;
}

.login-text h3 {
    width: 395px;
    height: 47px;
    top: 403px;
    left: 873px;
    color: black;
    font-size: 1.75rem;
}

.login-text button {
    width: 400px;
    background-color: #043369 !important;
}

@media screen and (max-width: 900px) {
    .login-bg {
        display: none;
    }

    .login-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 55vh;
    }

    .login-content {
        padding-left: 3rem;
        background-color: white;
        padding-right: 3rem;
    }

    .login-text button {
        width: 73vw;
    }

    .login-logo {
        padding-top: 87px;
    }

    body {
        background-color: white;
    }

    .login-row {
        overflow: hidden;
    }
}

.hover-bg {
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
    border-radius: 4px;
}

.hover-bg:hover {
    background-color: #fafafa;
}

.custom-skeleton {
    background-color: #e0e0e0;
    animation: shimmer 1.5s infinite linear;
    border-radius: 4px;
}

.fit-content {
    width: fit-content;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

/* Pdf viewer */

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.opacity-50 {
    opacity: 0.5;
}

.h-100vh {
    height: 100vh;
}

/* Custom tooltip */

.custom-tooltip {
    position: relative;
    display: inline-block;
}

.custom-tooltip .custom-tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: #181c32;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 20px;
    position: absolute;
    z-index: 1;
    top: 25%;
    left: 110%;
}

.custom-tooltip .custom-tooltip-text::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #181c32 transparent transparent;
}

.custom-tooltip:hover .custom-tooltip-text {
    visibility: visible;
}

/* Multi select */

.multi-container {
    position: relative;
    width: 24em;
    min-height: calc(1.5em + 1.1rem + calc(1px * 2));
    border: 1px solid #e4e6ef;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.2em;
    border-radius: 0.475rem;
    outline: none;
}

.multi-container-lg {
    min-width: 30em;
    min-height: calc(1.5em + 2.75rem + calc(1px * 2));
}

.multi-container:focus {
    border-color: #c4c6cf;
}

.multi-value {
    flex-grow: 1;
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
}

.multi-clear-btn {
    background: none;
    color: #616162;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.25em;
}

.multi-clear-btn:focus,
.multi-clear-btn:hover {
    color: #616162;
}

.multi-divider {
    background-color: #616162;
    align-self: stretch;
    width: 0.05em;
}

.multi-caret {
    translate: 0 25%;
    border: 0.25em solid transparent;
    border-top-color: #616162;
    cursor: pointer;
}

.multi-options {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    max-height: 15em;
    overflow-y: auto;
    border: 0.05em solid #e4e6ef;
    border-radius: 0.475rem;
    width: 100%;
    left: 0;
    top: calc(100% + 0.25em);
    background-color: white;
    z-index: 100;
}

.multi-options.show {
    display: block;
}

.multi-option {
    padding: 0.25em 0.5em;
    cursor: pointer;
}

.multi-option.selected {
    background-color: #f8839c;
}

.multi-option.highlighted {
    background-color: #f1416c;
    color: white;
}

.multi-option-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.05em solid #e4e6ef;
    border-radius: 1rem;
    padding: 0.15em 0.25em;
    gap: 0.25em;
    cursor: pointer;
    background: none;
    outline: none;
    color: #616162;
    font-weight: 300;
    background-color: #D9D9D9;
}

.multi-option-badge:hover,
.multi-option-badge:focus {
    background-color: hsl(0, 100%, 90%);
    border-color: hsl(0, 100%, 50%);
}


.multi-option-badge:hover > .remove-btn,
.multi-option-badge:focus > .remove-btn {
    color: hsl(0, 100%, 50%);
}

.multi-option-badge > .remove-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    color: #FFFFFF;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #ACADAF;
    margin-left: 0.5em;
    padding-top: 0.125em;
}

/* Table */
.table-like .table-header,
.table-like .table-row {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.table-like .header-item,
.table-like .row-item {
    flex: 1;
    text-align: left;
}

.table-like .header-item:last-child,
.table-like .row-item:last-child {
    text-align: right;
}

/* Custom Toggle */
.bg-transparent.caret-off::after {
    display: none;
}

.btn-group.show .dropdown-toggle.dropdown-toggle-custom {
    box-shadow: none;
}

.dropdown-toggle-custom:focus {
    outline: none; /* Ensure no focus outline */
    box-shadow: none; /* Ensure no box-shadow on focus */
    user-select: none;
}

.dropdown-item.no-bg-change:active {
    background-color: transparent;
    user-select: none;
    color: inherit;
}

/* Custom Toggle */

/* custom select */
.form-select.currency-picker,
.form-control.currency-picker {
    color: #616162;
    font-weight: 300;
}

.form-select.currency-picker.filters-page {
    padding: 0.75rem 0 0.75rem 0.5rem;
}

/* custom select */

/* Financier menu */
.selected-financier-menu-tab {
    filter: invert(34%) sepia(100%) saturate(747%) hue-rotate(320deg) brightness(95%) contrast(101%);
}

.nav-item:hover img {
    filter: invert(34%) sepia(100%) saturate(747%) hue-rotate(320deg) brightness(95%) contrast(101%);
}

/* Financier menu */

/* Financier pages wrapper */
.financier-pages-wrapper {
    display: flex;
    flex-direction: column;
}

/* Financier pages wrapper */

/* StatusBar.css */

.status-bar-row {
    display: flex;
    gap: 10px; /* Adds space between the arrows */
}

.status-bar-container {
    width: 300px;
    height: 60px;
    position: relative;
    overflow: visible;
}

.status-item {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    clip-path: polygon(0% 0%, calc(100% - 30px) 0%, 100% 50%, calc(100% - 30px) 100%, 0% 100%);
}

/* .status-item::after {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  border-top: 25px solid transparent;
  border-left: 10px solid #f5f5f5;
  border-bottom: 25px solid transparent;
} */

/* .status-item:first-child {
  border-radius: 4px 0 0 4px;
}

.status-item:last-child {
  border-radius: 0 4px 4px 0;
} */

.status-item.active {
    background-color: #f54260; /* Active status color */
    color: white;
}

/* .status-item.active::after {
  border-left-color: #f54260;
} */

.arrows-row {
    display: flex;
    border: 1px solid; /* Adds space between the arrows */
}

.arrow-container {
    width: 150px;
    height: 60px;
    position: relative;
    overflow: visible;
}

.arrow {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: bold;
    position: relative;
    clip-path: polygon(0% 0%, calc(100% - 30px) 0%, 100% 50%, calc(100% - 30px) 100%, 0% 100%);
}

.arrow.active {
    background-color: #e91e63;
    color: white;
}

.progress-container {
    display: flex;
    align-items: center;
}

.progress-step {
    position: relative;
    height: 40px;
    line-height: 40px;
    padding: 0 20px 0 40px;
    background-color: #fff;
    color: #333;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
}

.progress-step::before,
.progress-step::after {
    content: '';
    position: absolute;
    top: 0;
    border: 20px solid transparent;
}

.progress-step::before {
    left: 0;
    border-left-color: #000;
}

.progress-step::after {
    right: -40px;
    border-left-color: #fff;
    z-index: 1;
}

.progress-step:first-child {
    padding-left: 20px;
}

.progress-step:first-child::before {
    display: none;
}

.progress-step.completed {
    background-color: #e91e63;
    color: #fff;
}

.progress-step.completed::after {
    border-left-color: #e91e63;
}

.progress-step.incompleted {
    background-color: #fff;
    color: #000;
}

.progress-step.incompleted::after {
    border-left-color: #fff;
}

.progress-step:not(:first-child):after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    bottom: auto;
    right: -10px;
    border-top: 16px solid transparent;
    border-bottom: 17px solid transparent;
    border-right: none;
    border-left: 11px solid white;
}

.progress-step:not(:first-child):before {
    right: -11px;
    border-left-color: #C9CCD2;
}

/*card detail css*/
.tab-buttons {
    color: #000000;
    font-weight: 500;
    display: block;
}

.tab-buttons:active {
    color: #EC345C;
}

.active-link {
    color: red !important; /* Active link will be red */
    font-weight: bold; /* Bold font for active link */
}

/* Styling for inactive tabs */
.inactive-link {
    color: black !important; /* Inactive links will be black */
    font-weight: normal;
}

/* Financier calls */
.call-detail-item {
    margin-bottom: 1rem; /* mb-3 */
    padding: 1rem; /* p-3 */
    border-bottom: 1px solid #d1d1d1; /* border-bottom border-1 border-gray-200 */
    width: 100%; /* w-100 */
}

.tab-header {
    padding: 10px;
    border-radius: 8px;
}

.tab-count {
    border-radius: 4px;
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.phone-icon-transform {
    transform: rotate(90deg);
}

.light-gray-text {
    color: #848484;
}

/* Financier calls */

.progress-tracker {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

.step {
    position: relative;
    padding: 10px 30px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-left: none;
    border-right: none;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    flex-direction: row;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
    height: 100%;
    flex-grow: 1;
}

.ps-step {
    padding-left: 9.2%;
}

.step:last-child {
    border-right: 1px solid #ddd;
}

.step:first-child {
    border-left: 1px solid #ddd;
}

.step.active {
    background-color: #EC345C; /* Purple color for active state */
    color: white;
    border-color: #EC345C;
}

.step.active::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -16px;
    width: 30px;
    height: 31px;
    background-color: #EC345C;
    border-right: 1px solid #ddd;
    transform: translateY(-50%) rotate(45deg);
    z-index: 1;
}

.step::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -16px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    transform: translateY(-50%) rotate(45deg);
    z-index: 1;
}

.step:last-child::after {
    display: none;
}

.step:hover {
    background-color: #fff;
}

.step.active:hover {
    background-color: #EC345C;
}

/* Kanban */
.kanban-container {
    display: flex;
    overflow-x: auto;
    gap: 1rem; /* Adjust the gap between columns as needed */
}


.kanban-column {
    flex: 0 0 auto; /* Prevent columns from shrinking */
    width: 300px; /* Adjust the width of each column as needed */
}

.custom-modal-header .close {
    font-size: 2.8rem; /* Increase the size of the close icon */
    color: #000; /* Change color as needed */
    background: none; /* Remove background */
    border: none; /* Remove border */
    outline: none; /* Remove focus outline */
    box-shadow: none; /* Remove any shadow */
}

/* Kanban */

/*Email modal design start*/
.custom-modal-header {
    /* background-color: #f8f9fa; */
    border-bottom: 1px solid #ddd;
}

.custom-modal-body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

.custom-modal-body .email-details {
    margin-bottom: 15px;
}

.custom-modal-body .email-body {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #555;
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.1);
}

.custom-modal-header .text-modal-primary {
    color: #f1416c;
}

.custom-modal-body .text-modal-secondary {
    color: #6c757d;
}

.text-muted {
    color: #868e96;
}

hr {
    margin: 15px 0;
    border-top: 1px solid #e9ecef;
}

/*Email modal design end*/

/*Pagination data*/
.custom-pagination .page-item a {
    color: #7e8299 !important;
}

.custom-pagination .page-item.active > .page-link {
    background-color: #f1416c !important;
    color: white !important;
}

.custom-table {
    background-color: #fff;
    color: #f1416c;
    font-weight: 700;
}

.details-pane {
    padding: 15px;
    background-color: white;
    position: fixed;
    top: 18%;
    /*overflow-y: auto;*/
    right: 0;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
}

.details-pane.visible {
    transform: translateX(0);
}

.stepper-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.stepper-item {
    text-align: center;
    flex: 1;
    position: relative;
}

.stepper-icon {
    width: 40px;
    height: 40px;
    background-color: #e0e0e0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 16px;
}

.stepper-icon.current {
    background-color: #007bff;
    color: #fff;
}

.separator {
    height: 2px;
    width: 100%;
    background-color: #e0e0e0;
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
}

.separator:last-child {
    display: none;
}

.custom-stepper-title {
    font-size: 14px;
    font-weight: 600;
}

.mobile-menu-container {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mobile-menu-container .nav-item {
    margin: 0.5rem 0;
}

.mobile-menu-container .nav-link {
    padding: 0.75rem 0;
    border-bottom: 1px solid #eee;
}

.mobile-menu-container .nav-link:last-child {
    border-bottom: none;
}

/* Hide mobile menu on larger screens */
@media (min-width: 768px) {
    .mobile-menu-container {
        display: none;
    }
}

/* Ensure proper spacing for mobile menu items */
@media (max-width: 767px) {
    .mobile-menu-container {
        width: 100%;
    }
    
    .mobile-menu-container .nav-link {
        padding: 1rem;
        border-bottom: 1px solid #f0f0f0;
    }
    
    .mobile-menu-container .nav-link:last-child {
        border-bottom: none;
    }
}

/* Utility classes for responsive heights */
.h-25px {
  height: 25px !important;
}

@media (min-width: 768px) {
  .h-md-40px {
    height: 40px !important;
  }
}

/* Responsive margin utilities */
.ms-2 {
  margin-left: 0.5rem !important;
}

@media (min-width: 768px) {
  .ms-md-0 {
    margin-left: 0 !important;
  }
}

/* Increase hamburger menu size */
.fs-2 {
  font-size: 10.75rem !important;
}

/* Negative margin utility */
.ms-n2 {
  margin-left: 50rem !important;
}

/* Additional height utility */
.h-30px {
  height: 30px !important;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fs-7 {
  font-size: 0.875rem !important;
}

.call-header-size {
    font-size: 14px !important;
}

@media (max-width: 768px) {
  .fs-md-6 {
    font-size: 1rem !important;
  }
}
